
import { defineComponent } from 'vue';

// 活动组合
export default defineComponent({
  name: 'ActivityCompose',
  setup() {
    return {};
  }
});
